import { createRouter, createWebHistory } from "vue-router";
import { memberRoutes } from "@/Member/router/member.routes";
import { adminRoutes } from "@/Admin/router/admin.routes";
import { loginRoutes } from "@/Login/router/login.routes";

const routes = [...memberRoutes, ...adminRoutes, ...loginRoutes];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
