export const memberRoutes = [
  {
    path: "/member",
    name: "member",
    component: () => import("../views/MemberView.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("../views/pages/Home/HomePage.vue"),
      },
      {
        path: "payment",
        name: "payment",
        component: () => import("../views/pages/RoyaltyPayment/RoyaltyPayment.vue"),
      },
      {
        path: "update",
        name: "update",
        component: () =>
          import("../views/pages/UpdatePage/UpdatePage.vue"),
      },
      {
        path: "advance",
        name: "advance",
        component: () =>
          import("../views/pages/PaymentAdvance/PaymentAdvance.vue"),
      },
      {
        path: "status",
        name: "status",
        component: () =>
          import("../views/pages/RequestStatus/RequestStatus.vue"),
      },
      {
        path: "upload",
        name: "upload",
        component: () =>
          import("../components/PaymentUploader/PaymentUploader.vue"),
      },
    ],
  },
];
