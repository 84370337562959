import { createStore } from "vuex";

export default createStore({
  state: {
    user: {
      id: "",
      email: "",
      name: "",
      memberCode: "",
      ci: "",
      phone: "",
      telf: "",
      rol: "",
    },
  },
  mutations: {
    setUserStore(state, user) {
      state.user = user;
    },
  },
  actions: {
    updateUserStore({ commit }, user) {
      commit("setUserStore", user);
    },
  },
  getters: {
    getUserStore: (state) => state.user,
  },
});
