export const adminRoutes = [
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/AdminView.vue"),
    children: [
      {
        path: "",
        name: "admin",
        component: () => import("../views/pages/Home/HomePage.vue"),
      },
      {
        path: "table",
        name: "table",
        component: () => import("../views/pages/Update/UpdateTable.vue"),
      },
      {
        path: "updateAdmin",
        name: "updateAdmin",
        component: () => import("../views/pages/UpdateForm/UpdateForm.vue"),
        props: (route) => ({ id: route.query.id }),
      },
      {
        path: "reports",
        name: "reports",
        component: () => import("../views/pages/Reports/ReportsPage.vue"),
      },
      {
        path: "request-table",
        name: "request-table",
        component: () => import("../views/pages/Request/RequestPage.vue"),
      },
      {
        path: "member-table",
        name: "member-table",
        component: () => import("../views/pages/Members/MemberPage.vue"),
      },
      {
        path: "update-member",
        name: "update-member",
        component: () => import("../views/pages/UpdateMember/UpdateMember.vue"),
      },
      {
        path: "update-request",
        name: "update-request",
        component: () =>
          import("../views/pages/UpdateRequest/UpdateRequest.vue"),
      },
    ],
  },
];
