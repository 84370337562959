export const loginRoutes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    children: [
      {
        path: "",
        name: "signIn",
        component: () => import("../components/SignIn/SignIn.vue"),
      },
    ],
  },
];
